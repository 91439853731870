/************* COLORS *************/
$orange-3up: #f0925a !default;
$orange-2up: #ee8546 !default;
$orange-1up: #ec7731 !default;
$orange: #eb6a1d !default;
$orange-1dn: #d6611b !default;
$orange-2dn: #c15718 !default;
$orange-3dn: #ab4e16 !default;

$color-blitts-purple: #656187 !default;
$color-blitts-lilac: #715ea1 !default;
$color-blitts-lilight: #d7cfe5 !default;
$color-blitts-ultraviolet: #edebef !default;

$color-alert-darker: #2b2d42 !default;
$color-alert-dark: #8d99ae !default;
$color-alert-neutral: #edf2f4 !default;
$color-alert-warn: #ef233c !default;
$color-alert-warnshade: #d22929 !default;

$base00: #181818 !default;
$base01: #282828 !default;
$base02: #383838 !default;
$base03: #585858 !default;
$base04: #b8b8b8 !default;
$base05: #d8d8d8 !default;
$base06: #e8e8e8 !default;
$base07: #f8f8f8 !default;

$color_two: #eee !default;

$primaryColor: $orange !default;
$disabledColor: #888 !default;

/************* COLORS with context variables *************/
$inactivelink: #a5a1b7 !default;

$odd: #ccc !default;
$even: $odd + $base02 !default;

$border_color: $base02 !default;
$color-border2: #c0c0c0 !default;

$filterbackground: #fdfbbe !default;
$filterborder: #aeadad !default;

$infobackground: $filterbackground !default;
$infoborder: 1px solid #fdd95b !default;

$unverifiedbackground: $filterbackground !default;

$type_i_color: $base02 !default;
$type_ii_color: #7474fb !default;
$type_iii_color: #965cfa !default;

$from-parent-background: #c2cfd8 !default;
$subcategorybackgroundcolor: #f6f5f5 !default;

$headingColor: $orange-1dn !default;

/************* LOGO *************/
$logoWidth: 112px !default;
$logoHeight: 82px !default;
$logoImage: image-url("BLITTS.svg") !default;
$logoMarginTop: 7px !default;
$logoBgSize: contain !default;

/************* HEADER *************/
$headerMarginBottom: 20px !default;
$headerBorderBottom: 1px solid $color-border2 !default;
$headerWidth: 977px !default;
$headerHeight: 96px !default;
$showHeaderLinks: true !default;
$headerRight: false !default;
$headerNavLeft: 130px !default;
$headerNavRight: 0 !default;
$headerNavColor: $color-blitts-lilac !default;
$headerNavBg: transparent !default;
$headerBg: transparent !default;
$headerDisplay: block !default;

/************* AUTH *************/
$authBorderTop: 0 !default;
$authBgColor: #505050 !default;
$authTextColor: $base05 !default;
$authRequestColor: #cfcfcf !default;

/************* CONTENT *************/
$contentH1Padding: 0 0 0 15px !default;
$contentBg: image-url("small-content-middle.png") repeat-y 0 21px transparent !default;
$contentBgGenerator: image-url("wide-content-middle.png") repeat-y 0 21px
  transparent !default;
$contentBoxShadow: 0 !default;
$contentH1Padding: 0 !default;
$contentH1Color: $headingColor !default;
$contentH1Bg: transparent !default;
$contentPadding: 0 0 0 16px !default;

/************* FOOTER *************/
$footerShowHouses: true !default;
$footerAllBg: transparent !default;
$footerColorBg: #bebbbb !default;
$footerColorText: #000 !default;

/************* INFO BLOCK *************/
$infoBlockColor: $color-blitts-lilac !default;
$infoBlockBg: $color-blitts-ultraviolet !default;
$infoBlockBoxShadow: 2px 2px 2px $base02 !default;
$tabContentColor: #000 !default;

/************* TAB CONTENT *************/
$tabContentBg: $color-blitts-lilight !default;

/************* FORM CONTAINER *************/
$formContainerBg: #eeecf3 !default;
$formContainerPadding: 20px !default;

/************* OTHER *************/
$mainBg: image-url("bg.png") repeat 0 0 transparent !default;
$containerBg: $color-blitts-purple !default;
$containerInnerSmallBg: image-url("small-content-top.png") no-repeat 0 21px
  transparent !default;
$containerInnerLargeBg: image-url("wide-content-top.png") no-repeat 0 21px
  transparent !default;
$schetsBottomBg: image-url("small-content-bottom.png") no-repeat 0 1px
  transparent !default;
$schetsBottomBgGenerator: image-url("wide-content-bottom.png") no-repeat 0 1px
  transparent !default;
$profileLinkColor: $primaryColor !default;
$adminMenuTop: 49px !default;
$adminSubMenuBg: white !default;
