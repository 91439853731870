.progressbar
{
    margin-bottom: 2em;

    @extend .clearfix;

    ul
    {
        margin: 0;
        padding: 0;

        list-style-type: none;
        li
        {
            float: left;

            padding: 12px 23px 0 34px;

            color: #838384;
            background: image-url('step.png') no-repeat;
        }
        li.done
        {
            color: $orange-1dn;
            background: image-url('step-done.png') no-repeat;
        }
        li.last
        {
            width: 16px;
            height: 16px;
            padding: 0;

            background: image-url('step-last.png') no-repeat;
        }
    }
}
