footer {
  height: 280px;

  background: $footerAllBg;

  .houses {
    @if ($footerShowHouses != true) {
      display: none
    }

    height: 124px;
    background: image-url('footer-houses.png') no-repeat center bottom transparent;
  }

  > .inner {
    background: $footerColorBg;
    > .inner {
      width: 1000px;
      height: 157px;
      margin: 0 auto 0;
      padding: 15px 0 0;

      p {
        margin: 0;
        color: $footerColorText;
        float: left;
        a {
          margin-left: 20px;
          color: $footerColorText;
        }
      }
    }
  }

  .social {
    float: right;
    margin-right: 50px;
    a {
      float: left;
      color: $footerColorText;
      margin-right: 20px;
    }
  }



  $logo-size: 25px;
  .dark-logo {
    width: $logo-size;
    height: $logo-size;
  }
}
