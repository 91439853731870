#authentication {
  background-color: $authBgColor;
  border-top: $authBorderTop;

  #inner-authentication {
    margin: 0 auto;
    width: 977px;
    height: 30px;
    line-height: 30px;
    color: $base04;
    font-size: 14px;
    background-color: $authBgColor;

    a {
      color: $authTextColor;

      .fa {
        margin-right: 5px;
      }
    }

    .session-nav {
      a ~ a { margin-left: 1.5em; }
      float: right;
    }

    div#requests {
      .count {
        display: block;
        border-radius: 8px;
        padding: 1px 10px;
        float: left;
        margin-left: 15px;
        margin-top: 4px;
        height: 20px;
        line-height: 20px;

        &:first-child {
          margin-left: 0;
        }

        &.finalized {
          background: #1e8e15;
        }

        &.open {
          background: #e95802;
        }
      }
      a {
        float: left;
        margin-left: 5px;
        color: $authRequestColor;
      }
    }
  }
}
