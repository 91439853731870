.dormers
{
    div.left
    {
        float: left;

        width: 250px;

        border-right: 1px solid #d7cfe5;
    }
    div.right
    {
        padding-left: 385px;
    }
    .form-field
    {
        margin-bottom: 20px;
    }
    img.dormer-example
    {
        margin-bottom: 30px;

        box-shadow: 2px 2px 2px #666;
    }
    span.bubble
    {
        padding: 5px 8px;

        color: #fff;
        border-radius: 15px;
        box-shadow: 2px 2px #666;
    }
    span.green
    {
        background-color: #71ae5b;
    }
    span.blue
    {
        background-color: #2a4fed;
    }
    span.grey
    {
        background-color: $color-blitts-purple;
    }
    span.orange
    {
        background-color: #ed722a;
    }
    span.red
    {
        background-color: #a5004a;
    }
    span.yellow
    {
        background-color: #d9b300;
    }
    span.purple
    {
        background-color: #9d50ce;
    }
    span.turquoise
    {
        background-color: #468186;
    }
}

.form-field,
.checkbox-field {
  @extend .clearfix;

  position: relative;
  margin-bottom: 1em;
}

.form-field
{
    label {
      font-weight: bold;
      display: inline-block;
      float: left;
      width: 30%;
      padding-right: 2em;
    }

    &.not-in-new-situation,
    &.dormer-windows
    {
        margin-top: 15px;

        .dormer-window
        {
            float: left;

            margin-right: 10px;
            padding: 0 10px;

            border: 1px solid darken($color-blitts-lilight, 4%);
            background: linear-gradient(
              darken($color-blitts-lilight, 4%),
              $color-blitts-lilight
            );

            h2
            {
              padding: 0;
            }
            .previous
            {
                float: left;
            }
            .next
            {
                float: right;
            }
        }
    }

    &.wall
    {
        padding-bottom: 20px;

        border-bottom: 1px solid #d7cfe5;
        label
        {
            font-size: 12px;

            float: left;

            margin-right: 10px;

            color: $base02;
        }
    }
}

fieldset
{
    padding: 1em;

    margin-bottom: 2em;

    border: 1px solid $color-blitts-lilight;

    legend.disabled-why {
      display: none;
    }

    &[disabled] legend.disabled-why {
      display: block;
    }
}

.form-container
{
    width: 526px;
    margin-bottom: 30px;
    padding: $formContainerPadding;

    color: $base02;
    background: $formContainerBg;

    @extend .clearfix;

    ul {
      padding: 0;
    }

    .admin &
    {
        width: auto;
    }

    &.image
    {
        img
        {
            border: 1px solid #ccc;
        }
    }

    &.document-upload
    {
        .partnerlogo
        {
            float: right;

            width: 100px;
            margin-left: 2em;
        }

        form
        {
            margin-top: 2em;
        }

        label
        {
            font-weight: bold;

            display: block;
        }

        .primary-button
        {
            float: right;
        }
    }

    .share-8
    {
        float: left;

        width: 8em;
    }

    div.result
    {
        padding: 35px 110px 30px 130px;

        border: 1px solid #afaeb3;
        background: image-url('check-green.png') no-repeat 100px 34px #fff;
        h3
        {
            font-size: 18px;

            margin: 0 0 20px;

            color: $orange-1dn;
        }
        p
        {
            color: $base02;
        }
        span
        {
            font-size: 14px;
            font-weight: bold;

            color: #7a68a7;
        }
        .login
        {
            font-size: 14px;
            font-weight: bold;

            padding-right: 30px;

            color: #7a68a7;
            background: image-url('arrow-purple.png') no-repeat 48px 2px #fff;
        }
        .register
        {
            font-size: 14px;
            font-weight: bold;

            padding-right: 30px;
            padding-left: 15px;

            color: #7a68a7;
            background: image-url('arrow-purple.png') no-repeat 142px 2px #fff;
        }
    }
    div.division
    {
        margin-top: 10px;
        padding-top: 10px;

        border-top: 1px solid #d7cfe5;
    }
    div.has-divider-fields
    {
        margin-top: 10px;
        padding-top: 10px;

        border-top: 1px solid #d7cfe5;
    }

    .radio-field
    {
        margin:
        {
            top: 10px;
        }
    }

    .form-field
    {
        margin:
        {
            top: 2em;
            right: 16px;
        };

        border: none;

        @extend .clearfix;

        .questionbox &
        {
            margin-bottom: 16px;

            color: $base02;
        }

        .form-field
        {
            margin-bottom: 16px;

            color: $base02;
        }

        span
        {
            margin-top: 5px;
            margin-right: 10px;

            color: $base02;
        }

        legend
        {
            font-weight: bold;

            color: $color-blitts-lilac;
        }

        &.error
        {
            input,
            select
            {
                border: 1px solid $orange-1dn;
            }
        }

        &.image-radios
        {
            font-weight: bold;

            margin-right: 0;

            color: $base02;
            div.radio-choice
            {
                float: left;

                margin: 15px 21px 15px 0;
            }
            input
            {
                float: none;
            }
            img
            {
                margin-top: 12px;

                box-shadow: 2px 2px 2px #666;
                &.no-shadow
                {
                    box-shadow: none;
                }
            }
        }

        &.image-radios.dormers
        {
            div.radio-choice
            {
                margin: 15px 10px 15px 0;
            }
        }

        &.image-radios.holes
        {
            width: 100%;

            border-bottom: 1px solid #d7cfe5;
        }

        &.sizes,
        &.position,
        &.columns,
        &.rows
        {
            display: block;
            float: left;

            width: 246px;
            margin-top: 18px;

            border-right: 1px solid #d7cfe5;
            h2
            {
                font-size: 13px;

                height: 40px;
            }
            .form-field-box
            {
                float: left;

                margin-right: 10px;
                label
                {
                    font-size: 12px;

                    color: $base02;
                }
            }
        }

        &.position,
        &.rows
        {
            border-right: none;
        }

        &.window
        {
            padding: 10px 0;

            border-top: 1px solid $color-blitts-lilight;
            border-bottom: 1px solid $color-blitts-lilight;
        }

        &.delete
        {
            margin-top: 10px;
            padding-top: 10px;

            border-top: 1px solid $color-blitts-lilight;
        }
    }

    div.ventilation
    {
        width: 100%;
    }
}
