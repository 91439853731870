.infoblocks {
  @extend .absolutely-fab;

  right: 20px;
  top: 65px;
}

.infoblock {
  box-shadow: $infoBlockBoxShadow;
  padding: 30px;
  width: 230px;
  margin-bottom: 20px;
  background: $infoBlockBg;

  .info-arrow {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right:12px solid $infoBlockBg;
    margin-left: -42px;
    float: left;
  }

  &.hidden {
    display: none;
  }

  h2 {
    margin: 0px;
    padding: 3px 0 3px 30px;
    background: image-url('exclamationmark.png') no-repeat 0px 1px transparent;
    color: infoBlockH2Color;
    font-size: 14px;
  }

  .image_tabs {
    ul {
      padding: 0;
      margin: 0;
      height: 22px;
      li {
        list-style-type: none;
        float: left;
        margin-right: 5px;
        border: 1px solid $color-blitts-lilight;
        height: 14px;
        padding: 4px 10px;
        border-radius: 4px 4px 0px 0px;
        a {
          font-weight: bold;
          color: #838384;
          text-decoration: none;
        }
      }
      li.active {
        background-color: #FFF;
        a {
          color: #E76D2E;
        }
      }
    }
    .tab {
      border: 1px solid $color-blitts-lilight;
      display: none;
    }
    .tab.active {
      display: block;
    }
  }
  .image_container {
    position: relative;
    background: white;
    min-height: 75px;
    .preview_loader {
      margin-top: 10px;
    }
  }
  .image_container.loading {
    .preview_loader {
      display: block;
    }
    .preview_image {
      display: block;
      opacity: 0.2;
      background: white;
    }
  }
  .image_caption {
    text-align: center;
    padding-bottom: 10px;
  }
}

.generator {
  .infoblock {
    @extend .absolutely-fab;

    background: #fff;
    border: 1px dashed #c5c5c5;
    box-shadow: none;
    padding: 10px;
    right: 50px;
    top: 112px;
    width: 280px;
    z-index: 100;

    .info-arrow {
      display: none;
    }

    h2 {
      background: none;
      font-size: 22px;
      color: #949494;
      padding: 0 0 20px 10px;
      font-weight: normal;
    }
  }
}
