$color-dso-green: #39870c;
$color-dso-lightgreen: #e1eddb;

.dso-window {
  min-height: 10em;
  margin: 1em 0 2em 0;
  background-color: $color-dso-lightgreen;
  padding: 1em;

  img.logo {
    float: right;
  }

  form.button_to {
    display: inline;
  }
}