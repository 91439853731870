.turner {
  @extend .absolutely-fab;
}

.turn.window
{
    .turntype-container
    {
        position: relative;

        width: 160px;
        height: 206px;

        .turn_image
        {
            top: 20px;
            left: 20px;
        }
        .turn_left
        {
            top: 95px;
            left: 0;
        }
        .turn_right
        {
            top: 95px;
            right: 0;
        }
        .turn_bottom
        {
            bottom: 0;
            left: 72px;
        }
        .turn_top
        {
            top: 0;
            left: 72px;
        }
        .turn_direction
        {
            top: 96px;
            left: 34px;
        }
    }
}


.turn.door
{
    padding: 10px 0;

    border-top: 1px solid $color-blitts-lilight;
    border-bottom: 1px solid $color-blitts-lilight;
    .turntype-container
    {
        position: relative;

        width: 136px;
        height: 232px;
        .turn_image
        {
            top: 20px;
            left: 20px;
        }
        .turn_left
        {
            top: 106px;
            left: 0;
        }
        .turn_right
        {
            top: 106px;
            right: 0;
        }
        .turn_direction
        {
            top: 150px;
            left: 21px;
        }
    }
}
