.building-object {
  textarea {
    padding: 3px 6px;

    border: 1px solid $color-blitts-purple;

    &:disabled {
      background-color: $color-blitts-ultraviolet;
    }
  }

  fieldset {
    position: relative;

    margin: 1em 0;
    padding-top: 3.5em;

    border: 0;
    border-top: 1px dashed $color-blitts-ultraviolet;
  }

  legend {
    @extend .absolutely-fab;
    top: 10px;
    left: 0;

    font-style: italic;
  }

  .form-field {
    label {
      color: $color-blitts-purple;
    }

    input,
    select,
    .turntype-container {
      float: left;
    }

    span {
      margin-top: 0.5rem;
      margin-left: 1em;
      float: left;
    }
  }

  .tooltip {
    font-size: 20px;

    margin-left: 1em;

    color: $color-blitts-purple;

    &:hover:after {
      @extend .absolutely-fab;

      font-family: Arial;
      font-size: 13px;
      line-height: 1.5;

      z-index: 100;
      bottom: 130%;
      left: 50%;

      padding: 5px 12px;

      color: $color-blitts-ultraviolet;
      border-radius: 2px;
      background: $color-blitts-purple;
    }
  }

  .tooltip-thickness-warning:hover:after {
    width: 220px;

    content: "Houdt u met het plaatsen van een element rekening met een standaard muurdikte van 300mm. Als u deze marge niet gebruikt zult u een foutmelding krijgen. Bij een woningscheidende muur is de marge de helft, dus 150mm.";
  }

  .active-section {
    margin-top: 1em;
    padding-top: 1em;

    border-top: 1px dashed $color-blitts-ultraviolet;
  }

  .title {
    padding: {
      top: 1em;
      bottom: 0.5em;
    }

    .used-id {
      font-size: 80%;

      padding: 2px 6px;

      vertical-align: text-top;

      color: white;
      border-radius: 15px;
      background: #71ea5b;
      box-shadow: 2px 2px #666;
      &:empty {
        display: none;
      }
    }
  }
}
