.section-toggle
{
  display: none;

  .holeable-type-wall .wall-window-section,
  .holeable-type-dormer .dormer-window-section,
  &.active-section
  {
    display: block;
  }
}
