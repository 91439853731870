.answer-field
{
    :disabled
    {
        color: $base04;
    }

    label
    {
        display: inline-block;

        padding-right: 1em;
    }

    .error
    {
        margin: 10px 0 5px 2px;
        float: right;
    }
}

.numeric
{
    width: 6em;

    text-align: right;
    font-variant-numeric: tabular-nums;
    @extend .chunky-input;

    .tab-content &
    {
        border-color: darken($color-blitts-lilight, 10%);
    }

    [disabled] &
    {
        color: $base02;
    }
}

.boolean-answer-field
{
    margin:
    {
        top: 1em;
        bottom: 1em;
    }
    input,
    label
    {
        vertical-align: middle;
    }
    label
    {
        font-size: 13px;

        display: inline;

        margin: 10px 0 5px;

        color: $color-blitts-lilac;
    }
    .error
    {
        display: block;
        float: right;

        padding-left: 21px;
    }
}
