body {
  font-family: arial;
  font-size: 12px;
  color: #000;
  margin:0px 0px;
  padding:0px;
}

body .yes_select,
body .no_select
{
  width: 200px;
}

.preview-image {
  max-width: 100%;
}

.search_emails {
  background: $color-alert-dark;
  padding: 1em 0 1em 0;
  margin-bottom: 1em;

  .container {
    font-size: 16px;
    padding-left: 1em;
    padding-right: 1em;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;

    input[type='search'] {
      width: 300px;
      height: 30px;
      background: $color-alert-neutral;
      border: none;
      font-size: 16px;
      color: #63717f;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      margin-left: 1em;
      padding: 0 1em 0 1em;
      transition: background-color .55s ease;

      &:hover,
      &:focus,
      &:active {
        outline: none;
        background: white;
      }

      &::placeholder {
        font-style: normal;
      }
    }

    button {
      color: white;
      font-size: 16px;
      text-decoration: none;
      display: inline-block;
      background: $orange;
      border-width: 0;
      height: 30px;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      transition: background-color .55s ease;
    }
  }
}

a {
  color: $color-blitts-purple;
  text-decoration: underline;
}

a.inactive {
  color: $inactivelink;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

hr {
  margin: 2px 0px;
  border: none;
  border-bottom: 1px solid $filterborder;
}

table.table {
  border-spacing: 2px;

  tr:nth-child(odd){
    background: $odd;
  }

  tr:nth-child(even){
    background: $even;
  }

  tr th {
    background: $color-blitts-purple;
    color: #E0E0E0;
  }

  td,
  th {
    padding: 3px 10px;
  }

  td.type_chk {
    width: 120px
  }
}

img.icon {
  vertical-align: top;
}

img.centered {
  margin: auto;
  display: block;
}

table tr.inactive {
  color: $base02;
}

tr.decimal_type_i, tr.option_type_i, tr.boolean_type_i {
  color: $type_i_color;
  td.icon {
    background: image-url('bg.png') no-repeat 50% 50% transparent;
  }
}

tr.decimal_type_ii, tr.option_type_ii, tr.boolean_type_ii {
  color: $type_ii_color;
  font-style: italic;
  td.icon {
    background: image-url('type_ii.png') no-repeat 50% 50% transparent;
  }
}

tr.decimal_type_iii, tr.option_type_iii, tr.boolean_type_iii {
  color: $type_iii_color;
  font-style: italic;
  td.icon {
    background: image-url('type_iii.png') no-repeat 50% 50% transparent;
  }
}

.maincategory {
  padding: 1px 10px 10px 10px;
  margin-bottom: 10px;
  background: darken($subcategorybackgroundcolor, 2%);
}

.subcategory {
  padding: 1px 10px 10px 10px;
  margin-bottom: 10px;
  background: $subcategorybackgroundcolor;
}

.long-textfield {
  width: 100%;
}

.long-textarea {
  width: 100%;
  height: 20em;
}

td.handle {
  cursor: move;
}

div.region_rule-button-area {
}

.hidden {
  display: none;
}

div#filter_form {
  position: relative;
}

.loading {
  @extend .absolutely-fab;

  top: 0px;
  z-index: 100;
  opacity: .4;
  background: white;
}

ul.regionrulewithfilters li {
  margin: 2px 0px 2px 20px;
  line-height: 25px;
  img.icon {
    margin-top: 3px;
  }
}

.question-field-tag {
  vertical-align: middle;
  margin-right: 5px;
}

.preview_image {
  max-width: 150px;
  max-height: 100px;
}

.preview_image-container{
   position: relative;
   height: 100px;
}

.remove_image{
  @extend .absolutely-fab;
  display: none;
}

select.full_width, input.full_width {
  width: 100%;
}

ul.questions {
  padding: 0;

  .category-header {
    line-height: 26px;
    font-weight: bold;
    .button {
      float: right;
    }
  }

  .category {
    padding: 10px;
    margin-bottom: 10px;
    list-style: none;
    border: 1px solid #ededed;
    background: #fafafa;

    .category-header .button {
      margin-right: 6px;
    }

    ul {
      padding-bottom: 10px;
    }

    .subcategories {
      padding: 0;
      .subcategory {
        padding: 5px;
        margin-bottom: 2px;
        background: #fff;

        .category-header {
          color: #808080;
          .button {
            margin-right: 0px;
          }
        }

      }

    }
  }
}


.operation-buttons-container {
  border: 1px solid #aeadad;
  background-color: #fdfbbe;
  display: block;
  overflow: auto;
  padding: 8px;
  margin: 2px;
  line-height: 29px;

  .operation-types {
    display: block;
    float: left;
    padding-left: 20px;
  }
  .label {
    float: left;
  }
}

.xl-col {
  min-width: 300px;
}

.label-default {
  background: $color-alert-neutral;
  padding: 1px 2px;
  border-radius: 4px;

  table tr:nth-child(odd) & {
    background: #bbb;
  }
}

.save-operations {
  display: none;
}

.operations {
  padding: 0;

  .operation {
    list-style: none;
    background: #F9F9F9;
    border: 1px solid #E8E8E8;
    overflow: hidden;
    padding: 8px;
    margin: 2px;
    line-height: 27px;

    .operation-field {
      float: left;
      form {
        width: 800px;
      }
    }
    .grip {
      width: 40px;
      height: 22px;
      float: left;
      cursor: move;
    }
    .button-group {
      float: right;
    }
    .button-right {
      float: right;
    }

  }
}


.primary-button-bar {
  border: 1px solid rgb(174, 173, 173);
  background-color: rgb(253, 251, 190);
  display: block;
  overflow: auto;
  padding: 8px;
  margin: 2px 2px 20px 2px;
  line-height: 29px;
}

.sbr-resources {
  width: 100%;


  th.name {

  }
  th.modified {
    width: 100px;
  }
  th.category {
    width: 100px;
  }
  th.edit-buttons {
    width: 51px;
  }
  td {
    padding: 5px;
  }
}

.sbr-questions {
  padding: 0;

  .sbr-single-question {
    background: #F9F9F9;
  }

  .sbr-open-question {
    background: #ECEFFA;
  }

  .has_answers {
    background: $filterbackground;
  }

  .sbr-question {
    width: 100%;
    list-style: none;
    border: 1px solid #E8E8E8;
    overflow: hidden;
    padding: 8px;
    margin: 2px;
    line-height: 27px;
    display: table;
    border-spacing: 10px;

    div {
      display: table-cell;
    }

    .original-id {
      width: 100px;
    }

    .text {

      h3 {
        padding: 0;
        margin: 0;
      }
      span {
        font-weight: bold;
      }
    }

    .possible-answers {
      width: 300px;

      menu {
        margin: 0;
        padding: 0;
        display: inline-block;
        width: 100%;
        overflow: auto;

        h3 {
          padding: 0;
          margin: 0;
          display: inline;
        }

        .button {
          float: right;
        }
      }

      span {
        font-weight: bold;
      }
    }
  }
}

/* backend menu */

.admin-menu {
  @extend .absolutely-fab;

  left: 130px;
  top: $adminMenuTop;
  width: 100%;

  ul {
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 100%;
    list-style: none;
    position: relative;
    display: inline-table;

    li:first-child {
      background: none;
    }
    li {
      background: image-url('nav-line.png') no-repeat 0 0 transparent;
      display: block;
      height: 38px;
      float: left;
    }
    li a {
      display: block;
      text-decoration: none;
      font-size: 16px;
      color: $headerNavColor;
      letter-spacing: 1px;
      padding: 14px 20px;
    }

    ul {
      @extend .absolutely-fab;
      display: none;
      border-bottom: 1px solid #c0c0c0;
      border-left: 1px solid #c0c0c0;
      border-right: 1px solid #c0c0c0;
      margin-bottom: 20px;
      background: $adminSubMenuBg;
      width: auto;
      padding: 0;
      top: 100%;
    }

    li:hover > ul {
      display: block;
      background: $adminSubMenuBg;
    }
  }

  ul:after {
    content: "";
    clear: both;
    display: block;
  }
}

.linked-sbr-answers {
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  position: relative;
}

.linked-sbr-answer {
  display: block;
  border: 1px solid green;
  overflow: auto;
  padding: 2px;
  margin: 2px 0;
  line-height: 27px;
  background: #F9F9F9;
   border: 1px solid #E8E8E8;

  .button-group {
    float: right;
  }
}


.sbr-answers-list {
  display: inline-block;
  padding: 0;
  margin: 0;
  li {
    display: block;
  }
}

.aanvrageninfo
{
  @extend .absolutely-fab;
  display: none;
  left: 52px;
  width: 1473px;
  padding: 10px;
  background: $base06;
  color: #000000;
  border: 1px solid #1a1a1a;
  font-size: 90%;
}

.categoryinfo
{
  @extend .absolutely-fab;

  left: 382px;
  width: 757px;
  padding: 10px;
  background: $base06;
  color: #000000;
  border: 1px solid #1a1a1a;
  font-size: 90%;
}

.antwoordeninfo
{
  @extend .absolutely-fab;
  display: none;
  left: 64px;
  width: 1449px;
  padding: 10px;
  background: $base06;
  color: #000000;
  border: 1px solid #1a1a1a;
  font-size: 90%;
}


table .inneranswers {
  /*border: 1px solid none;
  width: 100%;*/
  border-spacing: 2px;
  /*background: #000;
  border-collapse: inherit;*/
}

table .inneranswers td, table .inneranswers th {
  padding: 3px 10px;

}
table .inneranswers td.type_chk {
  width: 120px
}
table .inneranswers tr:nth-child(odd){
  background: #aba;
}

table .inneranswers tr:nth-child(even){
  background: #baa;
}

table .inneranswers tr th {
  background: $color-blitts-purple;
  color: #E0E0E0;
}

.icon-thumb {
  width: 30px;
  height: 30px;
}

.tree-depth {
  color: $color-blitts-lilac;
  display: inline-block;
  width: 1em;
}
