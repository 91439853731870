.loading-animation
{
    text-align: center;
}

.loading-bar
{
    display: inline-block;

    width: 4px;
    height: 18px;

    animation: loading 1s ease-in-out infinite;

    border-radius: 4px;

    &:nth-child(1)
    {
        animation-delay: 0;

        background-color: $orange-2up;
    }

    &:nth-child(2)
    {
        animation-delay: .09s;

        background-color: $color-blitts-lilight;
    }

    &:nth-child(3)
    {
        animation-delay: .18s;

        background-color: lighten($color-blitts-lilac, 15%);
    }

    &:nth-child(4)
    {
        animation-delay: .27s;

        background-color: $orange-1up;
    }
}

@keyframes loading
{
    0%
    {
        transform: scale(1);
    }
    20%
    {
        transform: scale(1, 2.2);
    }
    40%
    {
        transform: scale(1);
    }
}
