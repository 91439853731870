.top-buttons {
  @extend .clearfix;
  margin-bottom: 3em;
}

.bottom-buttons {
  @extend .clearfix;
  margin-top: 6em;
}

.absolutely-fab {
  position: absolute;
}

.navigation-buttons
{
    padding-top: 40px;

    @extend .clearfix;
}

.space-below {
  padding-bottom: 40px;

  @extend .clearfix;
}

.generator
{
    .terms
    {
        width: 570px;
    }
}

.sub-navigation-buttons
{
    width: 100%;
    margin-top: 20px;

    @extend .clearfix;
}

.questionbox
{
    padding: 22px 42px;

    border: 1px solid #d7cfe5;
    background: #eeecf3;

    h3
    {
        margin: 0 0 20px;

        color: $color-blitts-lilac;
    }

    label
    {
        font-size: 13px;
        font-weight: bold;

        display: block;

        margin: 10px 0 5px 2px;

        color: $color-blitts-lilac;
    }

    .questionnr
    {
        top: 22px;
        left: -8px;
    }

    .triangle
    {
        top: 38px;
        left: -15px;
    }

    .more-info
    {
        top: 20px;
        right: 20px;
    }
}

.trouble
{
    padding:
    {
        right: 10px;
    };

    border: 1px solid $color-blitts-lilac;
}

.gemeente_opmerking
{
    width: 526px;
    margin: 0 0 3px 0;
    padding: 2px 0 3px 30px;

    background: image-url('exclamationmark.png') no-repeat 0 0 transparent;
}

.default-purple
{
    margin:
    {
        top: 3em;
    };

    border:
    {
        top: 1px solid $color-blitts-lilight;
    }
    p
    {
        font-size: 13px;

        color: $color-blitts-lilac;
    }
}

.note
{
    margin: 2em 0;
    padding: 1em 4em;

    border:
    {
        top: 1px solid #ccc;
        left: 1px solid #ccc;
    };
    box-shadow: 2px 2px 2px #666;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6
    {
        margin:
        {
            top: 0;
        }
    }

    .category-icon
    {
        float: right;

        width: 90px;
        height: 90px;
    }
}

.fieldWithErrors
{
    display: table;

    padding: 2px;

    background-color: red;
}

.form-validation-fail
{
    margin:
    {
        bottom: 1em;
    };
    padding: 7px 7px 12px 7px;

    border: 1px solid #eb6c22;
    background: linear-gradient(to bottom left, #eeecf3 10%, #ffc39e 100%);
    box-shadow: 5px 5px 20px #cbcdd3;

    li
    {
        padding:
        {
            left: 2em;

            color: #eb6c22;
        }
    }
}

#unsupported_override_text {
  display: none;
}

.warningExplanation
{
  margin: 2em 0;
  padding: 0 1em;

  border: 2px solid $color-alert-warn;
  color: $base02;
  background: $base06;

  label {
    display: block;
    font-weight: bold;
    margin: 1em 0;
  }
}

.form-validation-fail,
.warningExplanation
{
    h2
    {
        font-size: 12px;
        font-weight: bold;

        margin: -7px;
        padding: 5px 5px 5px 15px;

        text-align: left;

        color: #fff;
        background-color: #c00;
    }

    ul li
    {
        font-size: 12px;
        margin-left: 2em;
        padding-left: 0;
        list-style: square;
    }
}
