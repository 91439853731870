.btn-base
{
    font-size: 16px;

    display: inline-block;

    padding: 7px 13px 7px 13px;

    cursor: pointer;
    transition: background-color .4s;
    white-space: nowrap;
    text-decoration: none;

    color: #fff;
    border: none;
    background-color: $color-alert-darker;
}

.small-transparent-button
{
    font-size: 12px;

    padding: 4px 8px;

    color: $color-blitts-purple;
    border: 1px solid $color-blitts-purple;
    border-radius: 2px;
    background: none;

    @extend .btn-base;

    &:hover,
    &.active
    {
        color: white;
        background-color: $color-alert-dark;
    }

    &.toggler {
      margin: {
        bottom: 1em;
      }
    }
}

.wizard-button
{
    padding: 7px 13px 7px 13px;

    text-align: center;

    color: white;

    @extend .btn-base;
}

.wizard-button-next,
.wizard-button-save
{
    float: right;

    border: 1px solid #d75b12;
    background: linear-gradient($orange, $orange-2up);
    background-color: $orange-2up;

    @extend .wizard-button;

    &:hover {
      background: linear-gradient($orange-2up, $orange);
    }

    &[disabled] {
      cursor: not-allowed;
      background: $base06;
      border: 1px solid $base05;
    }

}

.wizard-button-previous,
.wizard-button-delete
{
    border: 1px solid #464444;
    background: linear-gradient($color-blitts-purple, $color-blitts-lilac);
    background-color: $color-blitts-lilac;

    @extend .wizard-button;

    &:hover
    {
        background: linear-gradient($color-blitts-lilac, $color-blitts-purple);
    }
}

.sub-navigation-buttons
{
    .wizard-button-delete,
    .wizard-button-save
    {
        font-size: 12px;

        float: none;

        padding: 5px 10px 5px 10px;
    }
}

a
{
    &.disabled
    {
        cursor: not-allowed;
        pointer-events: none;

        background-color: #888;
    }
}

.button
{
    font-size: 16px;

    display: inline-block;

    padding: 7px 13px 7px 13px;

    cursor: pointer;
    transition: background-color .5s;
    text-decoration: none;

    color: #fff;
    border: none;

    &[disabled]
    {
        background-color: #888;
    }

    &.easygoin
    {
        font-size: 12px;

        margin-bottom: 6px;

        border: 1px solid #eb6a1d;
        border-radius: 2px;

        .fa
        {
            margin-right: 1em;
        }

        a
        {
            text-decoration: none;
        }

        &.primary-button .fa,
        &.primary-button a
        {
            color: white !important;
        }
    }
}

.default-button
{
    background-color: $color-blitts-purple;

    &:hover,
    &.active
    {
        background-color: black;
    }
}

.primary-button
{
    background-color: $orange;

    &:focus
    {
        outline: 2px dotted $orange-2up !important;
    }
}

.secondary-button
{
    background-color: $color-blitts-purple;

    &:focus
    {
        outline: 2px dotted lighten($color-blitts-purple, 20%) !important;
    }
}

.delete-button
{
    font-size: 16px;

    float: left;

    padding: 7px 13px 7px 13px;

    cursor: pointer;
    text-decoration: none;

    color: #fff;
    border: none;
}

.downloadbutton
{
    font-size: 16px;

    display: inline-block;

    margin-top: 20px;
    padding: 7px 13px 7px 13px;

    cursor: pointer;
    text-decoration: none;

    color: #fff;
    border: none;
}

.btn-xs
{
    font-size: 10px;

    padding: 0 5px 0 5px;
}

ul.share-buttons
{
    margin: 0;
    padding: 0;

    list-style: none;
}

ul.share-buttons li
{
    display: inline;
}

ul.share-buttons .sr-only
{
    @extend .absolutely-fab;

    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);

    width: 1px;
    height: 1px;
    padding: 0;

    border: 0;
}
