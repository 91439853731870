.flashcontainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  opacity: 0;
  transform: scale(0.95) translate3d(0, 100%, 0);
  transition: transform 400ms ease, opacity 400ms ease;

  text-align: center;
  padding: 10px;
  z-index: 9999;

  &.active {
    opacity: 1;
    transform: scale(1.0) translate3d(0, 0, 0);
  }

  &.inactive {
    opacity: 0;
    transform: scale(0) translate3d(0, 0, 0);
  }

  &#flash_alert {
    color: $subcategorybackgroundcolor;
    background: $color-alert-warnshade;
    border: $color-alert-warn;
  }

  &#flash_notice,
  &#flash_custom {
    border: $infoborder;
    background: $infobackground;
  }

  .wrapper {
    margin: 0 auto;
    width: 999px;
  }

  .msg {
    float: left;
    padding-left: 5px;
    font-size: 14px;
    width: 978px;
    text-align: center;
  }

  a.close {
    display: block;
    margin-top: 10px;
    color: inherit;
    font-style: italic;
    cursor: pointer;
  }
}
