ul.icon_bullets {
  color: $color-blitts-purple;
  padding: 0;
  margin: {
    top: 1em;
  }
  list-style-type: none;

  .fa {
    display: inline-block;
    width: 1.5em;
  }
}

dt {
  float: left;
  clear: left;
  width: 100px;
  text-align: right;
  font-weight: bold;
}

dd {
  margin: 0 0 0 110px;
  padding: 0 0 0.5em 0;
}

ul.small li {
  padding-left: 4px;
  padding-right: 4px;
}

li.rulewithfilters {
  border: 1px solid $filterborder;
  border-top: none;
  list-style-type: none;
  padding: 5px;
  margin: 0;
  overflow: auto;
  background-color: $filterbackground;
  div.question_checkbox {
    float: right;
  }
}

li.rulewithfilters_selectable {
  border: 1px solid $filterborder;
  border-top: none;
  list-style-type: none;
  padding: 5px;
  margin: 0;
  overflow: auto;
  background-color: #C7FFBF;
  div.question_checkbox {
    float: right;
  }
}
li.rulewithfilters_selectable:first-child {
  border-top: 1px solid $filterborder;
}

li.rulewithfilters a.delete_rule {
  float: right;
  color: blue;
}

li.unverified {
  background: $unverifiedbackground;
}

li.refer_filter {
  background: #bebefd;
  span.refer {
    font-style: italic;
    text-decoration: underline;
  }
}

li.rulewithfilters img.icon {
  padding-left: 4px;
}

li.rulewithfilters img.icon:first-child {
  padding-left: 0px;
}

li.rulewithfilters:first-child {
  border-top: 1px solid $filterborder;
}

li.from-parent-category {
  background: $from-parent-background;
}

li.inactive {
  background: $base06;
  color: $base02;
}

li.inactive a {
  color: $base02;
}

ul#groups {
  padding: 0;
}

li.group {
  list-style-type: none;
}

li.group ul {
  padding: 0;
  margin: 0;
  display: block;
}

ul#regionslist {

}

ul#regionslist li {
  list-style-type: dash;
}
