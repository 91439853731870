#container
{
    position: relative;

    background-color: $containerBg;
}

#inner-container
{
    font-size: 12px;

    position: relative;

    width: 672px;
    margin: 0 auto;
    padding: 27px 331px 42px 0;

    text-align: left;

    background: $containerInnerSmallBg;

    body.admin &
    {
        width: 1000px;
        padding: 35px 0;

        background: none;
    }

    body.generator &
    {
        width: 992px;
        padding: 35px 0 42px;

        background: $containerInnerLargeBg;
    }
}

#content
{
    margin-top: 20px;
    padding: $contentPadding;

    background: $contentBg;
    box-shadow: $contentBoxShadow;

    h1 {
        padding: $contentH1Padding;
        color: $contentH1Color;
        background: $contentH1Bg;
    }

    body.generator &
    {
        min-height: 600px;

        background: $contentBgGenerator;
        h1
        {
            margin-right: 12px;
        }
    }
}

#inner-content
{
    position: relative;

    min-height: 700px;
    padding: 20px 35px 40px;

    body.generator &
    {
        width: 550px;
    }

    body.generator.wideview &
    {
        width: auto;
    }
}

div#schets-bottom
{
  @extend .absolutely-fab;

    bottom: 22px;
    left: 0;

    width: 1000px;
    height: 46px;

    background: $schetsBottomBg;

    body.generator &
    {
      @extend .absolutely-fab;
        bottom: 22px;
        left: 0;

        width: 1000px;
        height: 46px;

        background: $schetsBottomBgGenerator;
    }
}
