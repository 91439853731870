// VAR'S
// ============================\\
$bgcolor: #ededed;
$borderColor: #0082a4;
$link: #fff;
$headingColor: #191919;
$headingBg: #89044c;
$button: $headingBg;
$buttonHover: #00a5c7;
$someTextColor: #191919;
$profileLinks: $borderColor;

/************* LOGO *************/
$logoMarginTop: 12px;
$logoWidth: 139px;
$logoHeight: 38px;
$logoImage: image-url("whitelabels/logo-aalsmeer.svg");

/************* header *************/
$showHeaderLinks: false;
$headerWidth: 1000px;
$headerHeight: 98px;
$headerMarginBottom: 0;
$headerBorderBottom: 0;
$headerNavLeft: 208px;
$headerNavColor: $someTextColor;

/************* AUTH *************/
$authBgColor: $headingBg;
$authTextColor: #fff;

/************* CONTENT *************/
$contentPadding: 0;
$contentBg: #fff;
$contentBoxShadow: 0 1px 1px 1px #ccc;
$contentH1Padding: 10px;
$contentH1Color: $headingColor;
$contentH1Bg: transparent;

/************* INFO BLOCK *************/
$infoBlockColor: $someTextColor;
$infoBlockBg: #fff;
$infoBlockBoxShadow: none;
$tabContentColor: #191919;

/************* TAB CONTENT *************/
$tabContentBg: #6d9dc5;
$tabContentColor: black;

/************* FOOTER *************/
$footerShowHouses: false;
$footerColorBg: #dfe0e1;

/************* OTHER *************/
$mainBg: none;
$containerBg: $bgcolor;
$containerInnerSmallBg: none;
$containerInnerLargeBg: none;
$schetsBottomBg: none;
$schetsBottomBgGenerator: none;
$authTextColor: $link;

@import "../application";
