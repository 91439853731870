.chunky-input {
  outline: none;
  -webkit-appearance: none;
  border: 3px solid $color-blitts-lilight;
  border-radius: 5px;
  background-color: #fff;
  vertical-align: middle;
  height: 26px;
  font-size: 14px;
  padding: 0 5px;

  &[required] {
    border-color: darken($color-blitts-lilight, 5%);
  }

  &[required] + span:after {
    content: ' (verplicht)';
  }
}

.field_with_errors {
  input {
    border: 3px solid $color-alert-warn;
  }
  .message  {
    color: $color-alert-warn;
    padding: 1px;
  }
}


.clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
