#logo {
  @extend .absolutely-fab;
  top: $logoMarginTop;
  left: 0;

  width: $logoWidth;
  height: $logoHeight;

  border: 0;
  background-image: $logoImage;
  background-size: $logoBgSize;
  background-repeat: no-repeat;
}

header {
  display: block;
  margin-bottom: $headerMarginBottom;

  border-bottom: $headerBorderBottom;
  background: $headerBg;

  > .inner {
    position: relative;

    width: $headerWidth;
    height: $headerHeight;
    margin: 0 auto;
    padding: 0;
  }

  nav {
    @extend .absolutely-fab;
    bottom: 0;
    @if $headerRight {
      right: $headerNavRight;
    } @else {
      left: $headerNavLeft;
    }

    background: $headerNavBg;
    ul {
      margin: 0;
      padding: 0;

      list-style-type: none;
      li {
        float: left;

        height: 28px;
        padding-top: 22px;

        background: image-url("nav-line.png") no-repeat 0 0 transparent;

        body.admin & {
          padding-top: 0;
        }
        a {
          font-size: 16px;

          padding: 14px 28px;

          text-decoration: none;
          letter-spacing: 1px;

          color: $headerNavColor;

          body.admin & {
            padding: 14px 12px;
          }
        }
        a.permit-link {
          padding: 14px 58px 14px 44px;

          color: #e47330;

          span {
            position: relative;

            &:after {
              content: "";
              position: absolute;
              top: -0.4rem;
              right: -20px;
              width: 14px;
              height: 29px;
              background: image-url("arrow-orange.png") no-repeat center
                transparent;
            }
          }
        }
      }
    }
  }

  #links {
    @if ($showHeaderLinks != true) {
      display: none;
    }
    @extend .absolutely-fab;
    top: 5px;
    right: 5px;
    ul {
      margin: 0;
      padding: 0;

      list-style-type: none;
      li {
        float: left;

        border-right: 1px solid $base02;
        a {
          font-size: 12px;

          padding: 0 8px;

          color: $base02;
        }
      }
      li:last-child {
        border-right: none;
        a {
          padding-right: 0;
        }
      }
    }
  }
}
