.pure-css-tabs {
  &.tabs-drawings {
    .tabs {
      width: 515px;
      height: 700px;

      color: black;

      > li > label {
        font-size: 12px;
        line-height: 10px;

        padding: 10px;

        background: #ddd;

        &:hover {
          background: darken(#ddd, 10);
        }
      }

      .tab-content {
        font-size: 12px;
        line-height: 1.5;

        top: 34px;

        min-height: 300px;
        width: 500px;

        background: $tabContentBg;
      }

      [id^="tab"]:checked + label {
        padding-top: 14px;

        background: $color-blitts-lilight;
      }
    }
  }

  &.tabs-light {
    .tabs {
      width: 100%;
      height: 560px;

      color: black;

      > li > label {
        font-size: 16px;

        background: #ddd;

        &:hover {
          background: darken(#ddd, 10);
        }
      }

      .tab-content {
        font-size: 12px;
        line-height: 1.5;

        background: linear-gradient($color-blitts-lilight, #d9e7ff);
      }

      [id^="tab"]:checked + label {
        background: #d9e7ff;
      }
    }
  }

  &.tabs-strong {
    .tabs {
      width: 550px;
      height: 230px;

      color: white;

      > li > label {
        font-size: 16px;
        line-height: 24px;

        text-transform: uppercase;

        background: $color-blitts-lilac;

        &:hover {
          background: darken($color-blitts-lilac, 10);
        }
      }

      .tab-content {
        font-size: 14px;

        background: darken($color-blitts-lilac, 15);
      }

      [id^="tab"]:checked + label {
        background: darken($color-blitts-lilac, 15);
      }
    }
  }

  p {
    margin-bottom: 1em;
  }

  .button {
    margin: {
      top: 1em;
    }
    padding: 7px 13px;
  }

  .tabs {
    position: relative;

    display: inline-block;
    float: none;

    margin: 10px 0 0;
    padding: 0;

    list-style: none;

    text-align: left;

    > li {
      display: block;
      float: left;
    }

    input[type="radio"] {
      @extend .absolutely-fab;
      top: 0;
      left: -9999px;
    }

    > li > label {
      font-weight: normal;

      position: relative;
      top: 4px;

      display: block;

      padding: 14px 21px;

      cursor: pointer;
      transition: all 0.2s ease-in-out;

      border-radius: 2px 2px 0 0;
    }

    .tab-content {
      @extend .absolutely-fab;
      z-index: 2;
      top: 49px;
      left: 0;

      display: none;
      overflow: hidden;

      padding: 25px;

      figure {
        margin: {
          top: 1em;
          bottom: 1em;
        }
        padding: 1em;

        background: rgba(255, 255, 255, 0.5);

        img {
          max-width: 100%;
        }
      }
    }
    //The Magic
    [id^="tab"]:checked + label {
      top: 0;

      padding-top: 17px;
    }

    [id^="tab"]:checked ~ [id^="tab-content"] {
      display: block;
    }
  }
}
