.ideal-logo {
  width: 78px;
  height: 70px;
}

.h-adr
{
    .p-street-address
    {
        display: block;
    }
    .p-postal-code
    {
        margin:
        {
            right: .6em;
        }
    }
}

.heading
{
    margin: 0;
    padding: 0 35px 10px;

    border-bottom: 1px solid $color-border2;

    h1
    {
        font-size: 18px;
        font-weight: normal;

        letter-spacing: 1px;

        color: $orange-1dn;
    }
    h1.small
    {
        margin: 0;
        padding: 0;

        color: inherit;
    }
    h2
    {
        font-size: 14px;

        margin: 0 0 3px 0;

        color: $color-blitts-lilac;
    }
    h2.big
    {
        font-size: 22px;
        font-weight: normal;

        margin: 20px 0;

        letter-spacing: 1px;
    }
    h3.small
    {
        display: inline-block;

        padding-right: 20px;
    }
}

::placeholder
{
    font-style: italic;

    color: #bbb;
}

.screenreader
{
    font-size: 0;
    line-height: 0;

    display: block;
    overflow: hidden;

    text-indent: -999px;
}

// delete this classname in inspector to show the content
.debug
{
    display: none;
}

body
{
    background: $mainBg;
}

a
{
    i
    {
        font-size: 120%;

        margin-right: 4px;

        vertical-align: middle!important;
        text-decoration: none!important;
    }
}

body.generator
{
    a.more-info
    {
        top: 160px;
        right: 390px;
    }
}

form.session-new
{
    label
    {
        display: block;
    }
}

form#postcodecheck
{
    input#postcode
    {
        width: 35%;
    }
    input#housenumber
    {
        width: 25%;
    }
    input#housenumber_addition
    {
        width: 15%;
    }
    input[type=submit]
    {
        float: none;

        margin-top: 10px;
    }
    p.postcodecheck-result
    {
        font-weight: bold;
    }
}

ul.requests
{
    margin: 0 0 30px;
    padding: 0;

    list-style-type: none;
     > li
    {
        display: block;

        min-height: 45px;
        margin:
        {
            bottom: 1em;
        };

        border: 1px solid #d7cfe5;
        background: $color-blitts-ultraviolet;

        img.icon
        {
            float: left;

            width: 50px;
            margin-right: 1em;
        }
        p
        {
            margin: 4px 0;
        }
        div.request-info
        {
            padding: 5px;

            border-bottom: 1px solid #d7cfe5;
            background: #fdfbbe;
            .icon
            {
                float: left;

                margin-right: 5px;
            }
        }
        div.request-content
        {
            position: relative;

            padding: 5px;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6
            {
                margin: 0;
            }
            p.request-title
            {
                font-weight: bold;
            }
            div.actions
            {
                top: 8px;
                right: 15px;

                width: 130px;

                @extend .absolutely-fab;

                .button
                {
                    width: 104px;
                }
            }
            a.request-action
            {
                display: inline-block;

                color: $profileLinkColor;
            }
            i
            {
                color: $profileLinkColor;
            }
        }
    }
}

ul.sub.categories
{
    li
    {
        float: none;

        width: 530px;
        height: 54px;

        background: none;
        img
        {
            float: left;
        }
        input
        {
            overflow: hidden;

            width: 100%;
            height: 54px;
            margin-left: 0;
            padding-right: 60px;
            padding-left: 20px;

            white-space: normal;
            word-wrap: break-word;

            background-position: 490px 22px;
        }
    }
}

ul.categories
{
    margin: 0 0 15px;
    padding: 28px 20px;

    list-style-type: none;

    background: #eeecf3;

    li
    {
        display: block;
        float: left;

        width: 260px;
        height: 52px;
        margin-right: 13px;
        margin-bottom: 5px;

        background-color: white;
        box-shadow: 1px 1px 1px #aaa;

        object
        {
            float: left;

            width: 60px;
            height: 52px;
        }

        a,
        input
        {
            font-family: arial;
            font-size: 14px;
            font-weight: bold;

            display: block;

            width: 200px;
            padding: 18px 0 16px 0;

            cursor: pointer;
            text-align: left;
            text-decoration: none;

            color: $color-blitts-lilac;
            border: none;
            background: image-url('arrow-orange-small.png') no-repeat 175px 20px #fefefe;
        }
        a:hover,
        input:hover
        {
            color: #e47330;
        }
    }
}

div.questionbox,
div.morequestions
{
    position: relative;
    .questionnr
    {
        font-weight: bold;

        z-index: 1;

        width: 6px;
        height: 16px;
        padding: 4px 15px;

        color: #fff;
        background: $color-blitts-lilac;
        -webkit-box-shadow: 2px 2px 0 rgba(0,0,0,.1);
           -moz-box-shadow: 2px 2px 0 rgba(0,0,0,.1);
                box-shadow: 2px 2px 0 rgba(0,0,0,.1);

        @extend .absolutely-fab;
             -o-box-shadow: 2px 2px 0 rgba(0,0,0,.1);
    }
    .triangle
    {
        width: 0;
        height: 0;

        border-width: 7px;
        border-style: solid;
        border-color: transparent #281b48 transparent transparent;

        @extend .absolutely-fab;
    }
}
div.morequestions
{
    height: 33px;
    margin-left: 8px;

    border-left: 1px solid #c5c5c5;
    .questionnr
    {
        top: 12px;
        left: -16px;
    }
    .triangle
    {
        top: 28px;
        left: -23px;
    }
}

div.morequestions.before
{
    .questionnr
    {
        top: 0;

        background: #afafaf;
    }
    .triangle
    {
        top: 16px;

        border-color: transparent #8a8a8a transparent transparent;
    }
}

.form-group
{
    margin-bottom: 1em;
}

input + span
{
    padding-left: .5em;
}


label.emphasis
{
    font-size: 110%;
}

.validation-error
{
    font-weight: bold;

    position: relative;
    left: -10px;

    margin-right: -20px;
    /*padding: 0 10px 10px 10px;*/
    padding: 10px;

    border: 1px solid $orange-1dn;
    background: #efdebf;
    background: #f4e3d9;

    input,
    select
    {
        border: 1px solid $orange-1dn;
    }
}

.help-text
{
    color: $orange-1dn;
}

.align-right
{
    text-align: right;
}

.document-is-here
{
    margin:
    {
        bottom: 1em;
    };
    padding:
    {
        bottom: 0;
    };

    border:
    {
        top: 1px solid $color-blitts-lilight;
    }
    h3
    {
        margin:
        {
            bottom: 0;
        }
        .fa
        {
            display: inline-block;

            width: 20px;
        }
    }

    .is-here-body
    {
        margin-left: 24px;
    }

    &.active
    {
        .remove_document
        {
            color: $color-alert-warn;
        }
        .remove-confirmation
        {
            display: block;
        }
    }
    .remove-confirmation
    {
        font-size: 14px;
        line-height: 2;

        display: none;

        padding: .5em 1em;

        color: $color-alert-neutral;
        background: $color-alert-warn;

        a
        {
            display: inline-block;
            float: right;

            padding: .2em 1em;

            text-decoration: none;

            color: $color-alert-warn;
            border-radius: 2px;
            background: $color-alert-neutral;

            &:hover
            {
                color: $color-alert-darker;
            }
        }

        .fa
        {
            margin-right: .6em;
        }
    }
}

textarea.answer_text_area
{
    width: 100%;
    height: 20em;

    border: 1px solid $color-blitts-lilight;
}

.option-answer-field
{
    input
    {
        left: -1px;
    }
    .error
    {
        margin: 10px 0 5px 2px;
        float: right;
    }
}

.form-horizontal
{
    width: 500px;
    margin-bottom: 1em;

    @extend .clearfix;

    fieldset
    {
        margin: 4em 0;
        padding: 0;

        border: 0;
    }

    legend
    {
        font-size: 16px;

        padding: 0;
    }

    label
    {
        font-size: 13px;
        font-weight: bold;
        line-height: 31px;

        display: inline-block;
        float: left;

        width: 160px;
        height: 31px;
        padding-right: 15px;

        color: $color-blitts-lilac;
    }

    .wide-label {
        width: auto;
    }

    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='tel'],
    input[type='url'],
    input[type='password']
    {
        width: 340px;
        padding: 6px 8px;

        border: 2px solid $color-blitts-lilight;
    }

    input[readonly]
    {
        background: $color-blitts-ultraviolet;
    }

    input[required] {
      border-color: darken($color-blitts-lilight, 5%);
    }

    select
    {
        width: 359px;
        padding: 6px 8px;

        border: none;
        background: $color-blitts-lilight;
    }

    &.housenr
    {
        width: 160px;
    }

    .bottom-buttons
    {
        padding-left: 173px;
    }
}

.form-third
{
    float: left;

    width: 30%;

    label
    {
        font-size: 13px;
        font-weight: bold;

        display: block;

        color: $color-blitts-lilac;
    }
}

a.new
{
    font-size: 14px;

    margin-top: 20px;
    padding: 8px 10px;

    text-decoration: none;

    color: white;
    background-color: $primaryColor;

    &.disabled
    {
        background-color: #888;
    }

    &.request
    {
        float: right;
    }
}

a.more-info
{
    top: 100px;
    right: 350px;

    display: block;

    width: 19px;
    height: 19px;

    background: image-url('questionmark.png') no-repeat 0 0 transparent;

    @extend .absolutely-fab;
}


.buttons
{
    width: 100%;
}





/* Building wizard */
div.fields
{
    .item
    {
        float: left;

        width: 315px;
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 5px;

        border: 1px solid #ddd;
    }
}

div.radio_images
{
    float: left;

    margin-bottom: 20px;
}

div.radio_image_choice
{
    float: left;

    margin-right: 5px;
    .radio_image
    {
        overflow: hidden;

        width: 163px;
        height: 303px;

        border: 1px solid #666;
    }
    img
    {
        margin-top: -303px;
    }
}

ul.wizard_route
{
    height: 40px;
    margin: 0;
    padding: 0 0 20px;

    list-style-type: none;

    border-bottom: 1px solid #ccc;
    li
    {
        position: relative;

        display: block;
        float: left;

        height: 40px;
        a
        {
            font-weight: bold;

            display: block;

            margin-right: 36px;
            padding: 10px;

            text-decoration: none;

            color: #666;
            border: 1px solid #666;
            background: #fff;
        }
        .arrow
        {
            top: 0;
            right: 0;

            border-width: 18px;
            border-style: solid;
            border-color: transparent transparent transparent #666;

            @extend .absolutely-fab;
        }
    }
    li.active
    {
        a
        {
            text-decoration: underline;

            color: #000;
            border-color: #000;
        }
        .arrow
        {
            border-color: transparent transparent transparent #000;
        }
    }
    li.last .arrow
    {
        display: none;
    }
    li:hover a
    {
        text-decoration: underline;
    }
}

p.rule-specs-header
{
    margin-bottom: 3px;
}

ul.rule-specs
{
    margin: 0 0 10px 15px;
    padding-left: 0;
    li
    {
        font-size: 11px;

        color: $orange-1dn;
    }
}

table.pricing {
  width: 100%;
  line-height: 2;
  border-spacing: 0;

  tr {
    background: #f6f6f6;
  }

  th,
  td {
    padding: 4px;
  }

  thead tr {
    background: $color-blitts-lilac;
    color: white;
  }

  tfoot {
    font-weight: bold;
    text-transform: uppercase;

    td {
      padding-top: 1em;
      border-top: 4px solid $color-blitts-lilac;
    }
  }

  .money {
    text-align: right;
    padding-right: 2em;
    font-variant-numeric: tabular-nums;
  }
}

table td.leftspace
{
    padding-left: 20px;
}

div.building_show
{
    position: relative;
}

.ui-widget
{
    font-family: inherit;
    font-size: 12px;
}

.ui-widget-content
{
    color: inherit;
}

.ui-tabs .ui-tabs-nav li a
{
    padding: .5em .9em;
}

.ui-widget input[type=submit]
{
    font-family: inherit;
    font-size: 16px;
}

.input-group
{
    margin-bottom: 1em;
}

.fieldsAsbestos,
.fieldsContractor,
.fieldsPostalAddres
{
    display: none;
}
